import OverlayNotificationModal from 'components/OverlayNotificationModal';
import { UserContext } from 'contexts/user/UserContextProvider';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import menuItems from '../constants/mainMenu';
import Footer from '../containers/navs/Footer';
import Sidebar from '../containers/navs/Sidebar';
import TopNav from '../containers/navs/Topnav';

const AppLayout = ({ containerClassnames, children, history }) => {
  const { currentUser } = useContext(UserContext);

  return (
    <div id="app-container" className={containerClassnames}>
      <OverlayNotificationModal />
      <TopNav history={history} />
      <Sidebar name="Main" menuItems={menuItems} currentUser={currentUser} />
      <main>
        <div className="container-fluid padding-for-footer">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};
const mapActionToProps = {};

export default withRouter(connect(mapStateToProps, mapActionToProps)(AppLayout));
