import SingleLightbox from 'components/custom/SingleLightbox';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../helpers/IntlMessages';

type wechatModalProps = {
  showModal: boolean;
  setShowWechatModal: any;
};
export default function UnsupportedWechatModal({ showModal, setShowWechatModal }: wechatModalProps) {
  const intl = useIntl();
  const handleClose = () => setShowWechatModal(false);
  const [copyButtonText, setCopyButtonText] = useState(
    intl.formatMessage({ id: 'user.login-wechat-guidance-button-copy' })
  );

  return (
    <Modal show={showModal} onHide={handleClose} animation={false}>
      <>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2 className="text-center">
            <IntlMessages id="user.login-wechat-guidance" />
          </h2>
          <p className="text-center">
            <Button
              href={'weixin://'}
              onClick={async () => {
                try {
                  await setCopyButtonText(intl.formatMessage({ id: 'user.login-wechat-guidance-button-copied' }));
                  await navigator.clipboard.writeText('Green_Steps');
                } catch (err) {
                  console.error('Failed to copy: ', err);
                }
              }}
            >
              {copyButtonText}
            </Button>
          </p>

          <SingleLightbox
            thumb="/assets/img/login/access-ark-from-wechat.jpeg"
            large="/assets/img/login/access-ark-from-wechat.jpeg"
            className="responsive border-0 card-img-top mb-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
}
