import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { NavLink } from 'react-router-dom';

const SingleLightbox = ({ thumb, className, large, style = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavLink to="#" location={{}} onClick={() => setIsOpen(true)}>
        <img src={thumb} alt=" " className={className} style={style} />
      </NavLink>

      {(isOpen)
        && <Lightbox
          mainSrc={large}
          onCloseRequest={() => { setIsOpen(false); }}
        />
      }
    </>
  );
};
export default SingleLightbox;
