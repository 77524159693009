import AppLayoutNoFooter from 'layouts/AppLayoutNoFooter';
import IframeLayout from 'layouts/IframeLayout';
import React from 'react';
import AppLayout from './layouts/AppLayout';
import { Forgot, Login, Register, Reset, Verify } from './pages/Auth';
import Home from './pages/Home';
import ChatFullscreen from 'pages/Chat/ChatFullscreen';

// android twa landing
const AndroidTwaLanding = React.lazy(() => import(/* webpackChunkName: "android-twa-landing" */ 'pages/AndroidTwa/AndroidTwaLanding'));

// error page
const ViewError = React.lazy(() => import(/* webpackChunkName: "error-page" */ './error'));

// user profile
const Profile = React.lazy(() => import(/* webpackChunkName: "user-profile" */ './pages/User/Profile'));
const BioRegion = React.lazy(() => import(/* webpackChunkName: "user-profile" */ 'pages/Bioregion/BioRegion'));
const Change = React.lazy(() => import(/* webpackChunkName: "user-profile" */ 'pages/Settings/Change'));
const UserCredits = React.lazy(() => import(/* webpackChunkName: "user-profile" */ 'pages/User/UserCredits'));
const ImpactInLearningPath = React.lazy(() => import(/* webpackChunkName: "user-profile" */ 'pages/User/ImpactInLearningPath'));
const Badges = React.lazy(() => import(/* webpackChunkName: "user-profile" */ 'pages/User/Badges'));

// user settings
const SettingLayout = React.lazy(() => import(/* webpackChunkName: "user-settings" */ 'layouts/SettingLayout'));
const EditProfile = React.lazy(() => import(/* webpackChunkName: "user-settings" */ 'pages/Settings/EditProfile'));
const RedeemImpactPoints = React.lazy(() => import(/* webpackChunkName: "redeem-ips" */ 'pages/User/RedeemImpactPoints'));

// redeem IPs
const DeleteProfile = React.lazy(() => import(/* webpackChunkName: "user-settings" */ 'pages/Settings/DeleteProfile'));

// standalone payment page
const Payment = React.lazy(() => import(/* webpackChunkName: "standalone-payment" */ './pages/Payment/Payment'));

// viewing activities
const EventsCourseList = React.lazy(() => import(/* webpackChunkName: "view-activity" */ './pages/EventCourse/EventCourseList'));
const EventDetail = React.lazy(() => import(/* webpackChunkName: "view-activity" */ './pages/EventCourse/EventDetails'));
const JoinActivity = React.lazy(() => import(/* webpackChunkName: "view-activity" */ 'pages/EventCourse/JoinActivity'));
const JoinActivityPaymentSuccess = React.lazy(() => import(/* webpackChunkName: "view-activity" */ 'pages/EventCourse/JoinActivityPaymentSuccess'));
const ProductDetails = React.lazy(() => import(/* webpackChunkName: "view-activity" */ 'pages/Community/ProductDetails/ProductDetails'));

// managing activities
const CourseDashboard = React.lazy(() => import(/* webpackChunkName: "manage-activity" */ './pages/EventCourse/CourseDashboard'));
const EventDashboard = React.lazy(() => import(/* webpackChunkName: "manage-activity" */ './pages/EventCourse/EventDashboard/EventDashboard'));
const CreateEditActivity = React.lazy(() => import(/* webpackChunkName: "manage-activity" */ './pages/EventCourse/CreateEditActivity'));
const Bookings = React.lazy(() => import(/* webpackChunkName: "manage-activity" */ './pages/EventCourse/Bookings'));

// embed community events
const EmbeddableCommunityEvents = React.lazy(() => import(/* webpackChunkName: "embed-community-events" */ 'pages/Community/EmbeddableCommunityEvents'));

// view/manage best practices
const CreateEditBestPractice = React.lazy(() => import(/* webpackChunkName: "view-best-practice" */ 'pages/BestPractice/CreateEditBestPracticeForm/CreateEditBestPractice'));
const BestPracticeDetails = React.lazy(() => import(/* webpackChunkName: "view-best-practice" */ 'pages/BestPractice/BestPracticeDetails'));
const BestPracticeList = React.lazy(() => import(/* webpackChunkName: "view-best-practice" */ 'pages/BestPractice/BestPracticeList'));

// manage community
const CreateCommunity = React.lazy(() => import(/* webpackChunkName: "manage-community" */ './pages/Community/CreateCommunity'));
const EditCommunity = React.lazy(() => import(/* webpackChunkName: "manage-community" */ './pages/Community/EditCommunity'));
const DashboardCommunityMembers = React.lazy(() => import(/* webpackChunkName: "manage-community" */ './pages/Community/Dashboard/ManageCommunityMembersPage'));
const ClassDashboard = React.lazy(() => import(/* webpackChunkName: "manage-community" */ 'pages/Community/SchoolClass/ClassDashboard'));
const ManageSchoolClasses = React.lazy(() => import(/* webpackChunkName: "manage-community" */ 'pages/Community/SchoolClass/ManageSchoolClasses'));
const ClassActivityDashboard = React.lazy(() => import(/* webpackChunkName: "manage-community" */ 'pages/EventCourse/ClassActivityDashboard'));

// view community
const CommunitiesList = React.lazy(() => import(/* webpackChunkName: "view-communities" */ './pages/Community/CommunitiesList'));
const CommunityProfile = React.lazy(() => import(/* webpackChunkName: "view-communities" */ './pages/Community/CommunityProfile'));
const CommunityEvents = React.lazy(() => import(/* webpackChunkName: "view-communities" */ './pages/Community/CommunityEvents'));
const CommunityMembers = React.lazy(() => import(/* webpackChunkName: "view-communities" */ './pages/Community/CommunityMembersPage'));
const SchoolReport = React.lazy(() => import(/* webpackChunkName: "view-communities" */ './pages/Community/SchoolReport'));

// view commons
const CommonsList = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/CommonsList'));
const CommonsProfile = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/CommonsProfile'));
const SpeciesDetailsPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Bioregion/SpeciesDetailsPage'));
const SpecimenDetailsPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Bioregion/Specimen/SpecimenDetailsPage'));
const SpecimenNav = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Bioregion/Specimen/SpecimenNav'));

// quests
const Play = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Play/Play'));
const RouteSignInInvitePage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteSignInInvitePage'));
const RouteFeedbackPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteFeedbackPage'));
const RouteDetailsPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteDetailsPage'));
const SelfGuidedRouteIntroPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/SelfGuidedRouteIntroPage'));
const MultiplayerManagePlayersPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/Multiplayer/MultiplayerManagePlayersPage'));
const MultiplayerIntroPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/Multiplayer/MultiplayerIntroPage'));
const MultiplayerOtherPlayerView = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/Multiplayer/MultiplayerOtherPlayerView'));
const RouteMapPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteMapPage'));
const RouteStopNav = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteStopNav'));
const RouteStopPage = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RouteStopPage'));
const RedirectFromShortUrl = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/RedirectFromShortUrl'));
const SpecimenQuiz = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Bioregion/Specimen/SpecimenQuiz'));

// quests designer
const QuestDashboard = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/QuestDashboard/QuestDashboard'));
const CreateEditQuest = React.lazy(() => import(/* webpackChunkName: "view-commons" */ 'pages/Commons/Quest/CreateEditQuest/CreateEditQuest'));

// view ecoregions
const EcoregionsList = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Ecoregions/EcoregionsList'));
const EcoregionProfile = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Ecoregions/EcoregionProfile'));
const EcoregionSpecimenDetailsPage = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Bioregion/Specimen/SpecimenDetailsPage'));
const EcoregionSpeciesDetailsPage = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Bioregion/SpeciesDetailsPage'));
const EcoregionSpeciesDashboard = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Bioregion/SpeciesDashboard'));
const EcoregionCreateEditSpecies = React.lazy(() => import(/* webpackChunkName: "view-ecoregions" */ 'pages/Bioregion/CreateEditSpeciesForm/CreateEditSpecies'));

// create/manage species/specimens
const CreateEditSpecies = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/CreateEditSpeciesForm/CreateEditSpecies'));
const CreateEditSpecimen = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/CreateEditSpecimenForm/CreateEditSpecimen'));
const CreateEditSpecimenThankYou = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/CreateEditSpecimenForm/CreateEditSpecimenThankYou'));
const SpecimenDashboard = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/Specimen/SpecimenDashboard'));
const SpeciesDashboard = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/SpeciesDashboard'));
const CreateSpeciesThankYou = React.lazy(() => import(/* webpackChunkName: "manage-species-specimens" */ 'pages/Bioregion/CreateEditSpeciesForm/CreateEditSpeciesThankYou'));

// map & mapping
const MapView = React.lazy(() => import(/* webpackChunkName: "view-mapping" */ 'pages/Mapping/MapView'));
const CreateEditMapping = React.lazy(() => import(/* webpackChunkName: "view-mapping" */ 'pages/Mapping/CreateEditMapping'));
const MappingList = React.lazy(() => import(/* webpackChunkName: "view-mapping" */ 'pages/Mapping/MappingList'));
const MappingToolIntro = React.lazy(() => import(/* webpackChunkName: "view-mapping" */ 'pages/Mapping/MappingToolIntro'));

// leaderboards
const CommunityLeaderboard = React.lazy(() => import(/* webpackChunkName: "leaderboards" */ './pages/Leaderboard/CommunityLeaderboard'));
const GlobalLeaderboard = React.lazy(() => import(/* webpackChunkName: "leaderboards" */ './pages/Leaderboard/GlobalLeaderboard'));

const routes = [
  {
    path: '/',
    layout: '',
    component: Home
  },
  {
    path: '/android-twa-landing',
    layout: '',
    component: AndroidTwaLanding
  },
  {
    path: '/events',
    layout: AppLayout,
    component: EventsCourseList
  },
  {
    path: '/communities',
    layout: AppLayout,
    component: CommunitiesList
  },
  {
    path: '/community/:communitySlug/members/manage',
    private: true,
    layout: AppLayout,
    component: DashboardCommunityMembers
  },
  {
    path: '/community/:communitySlug/members',
    private: true,
    layout: AppLayout,
    component: CommunityMembers
  },
  {
    path: '/community/:communitySlug/edit',
    layout: AppLayout,
    component: EditCommunity
  },
  {
    path: '/community/:communitySlug/events',
    layout: AppLayout,
    component: CommunityEvents
  },
  {
    path: '/community/:communitySlug/leaderboard',
    layout: AppLayout,
    component: CommunityLeaderboard
  },
  {
    path: '/community/create-community',
    private: true,
    layout: AppLayout,
    component: CreateCommunity
  },
  {
    path: '/community/:communitySlug',
    layout: AppLayout,
    component: CommunityProfile
  },
  {
    path: '/community/:communitySlug/classes',
    private: true,
    layout: AppLayout,
    component: ManageSchoolClasses
  },
  {
    path: '/community/:schoolCommunitySlug/report',
    private: true,
    layout: AppLayoutNoFooter,
    component: SchoolReport
  },
  {
    path: '/community/:schoolCommunitySlug/class/:classCommunitySlug/dashboard',
    private: true,
    layout: AppLayout,
    component: ClassDashboard
  },
  {
    path: '/community/:schoolCommunitySlug/class/:classCommunitySlug/:activitySlug/dashboard',
    private: true,
    layout: AppLayout,
    component: ClassActivityDashboard
  },
  {
    path: '/community/:schoolCommunitySlug/class/:classCommunitySlug/activity/new/:stepId?',
    private: true,
    layout: AppLayoutNoFooter,
    component: CreateEditActivity
  },
  {
    path: '/community/:communitySlug/product/:slug',
    layout: AppLayout,
    component: ProductDetails
  },
  {
    path: '/community/:communitySlug/events/embed/:count',
    layout: IframeLayout,
    component: EmbeddableCommunityEvents,
  },
  {
    path: '/community/:communitySlug/quest/new',
    layout: AppLayoutNoFooter,
    private: true,
    component: CreateEditQuest,
  },
  {
    path: '/community/:communitySlug/quest/edit/:questSlug',
    layout: AppLayoutNoFooter,
    private: true,
    component: CreateEditQuest,
  },
  {
    path: '/community/:communitySlug/quest/:questSlug/dashboard',
    layout: AppLayoutNoFooter,
    private: true,
    component: QuestDashboard,
  },
  {
    path: '/community/:communitySlug/route/:routeSlug',
    layout: AppLayoutNoFooter,
    component: RouteDetailsPage,
  },
  {
    path: '/course/:eventSlug/dashboard',
    layout: AppLayout,
    component: CourseDashboard
  },
  {
    path: '/course/:eventSlug',
    layout: AppLayout,
    component: EventDetail
  },
  {
    path: '/bookings',
    layout: AppLayout,
    private: true,
    component: Bookings
  },
  {
    path: '/event/:eventSlug/dashboard',
    layout: AppLayout,
    component: EventDashboard
  },
  {
    path: '/event/:eventSlug',
    layout: AppLayout,
    component: EventDetail
  },
  {
    path: '/activity/new/:stepId?',
    private: true,
    layout: AppLayoutNoFooter,
    component: CreateEditActivity
  },
  {
    path: '/activity/edit/:eventId/:stepId?', // ? means the pathname stepId is optional
    private: true,
    layout: AppLayoutNoFooter,
    component: CreateEditActivity
  },
  {
    path: '/join/:activitySlug',
    layout: AppLayout,
    private: true,
    component: JoinActivity
  },
  {
    path: '/user/:username/impact',
    layout: AppLayout,
    component: UserCredits
  },
  {
    path: '/user/:username/impact/:lpSlug',
    layout: AppLayout,
    component: ImpactInLearningPath
  },
  {
    path: '/user/:username/badges',
    layout: AppLayout,
    component: Badges
  },
  {
    path: '/user/:username/bioregion',
    layout: AppLayout,
    component: BioRegion
  },
  {
    path: '/user/:username',
    layout: AppLayout,
    component: Profile
  },
  {
    path: '/payment/success',
    private: true,
    redirect: '/events',
    layout: AppLayout,
    component: JoinActivityPaymentSuccess
  },
  {
    path: '/payment/:tradeId',
    private: true,
    redirect: '/events',
    layout: AppLayout,
    component: Payment
  },
  {
    path: '/library',
    layout: AppLayout,
    component: BestPracticeList
  },
  {
    path: '/library/create-best-practice/:stepId?',
    private: true,
    layout: AppLayoutNoFooter,
    component: CreateEditBestPractice,
  },
  {
    path: '/library/:slug',
    layout: AppLayoutNoFooter,
    component: BestPracticeDetails
  },
  {
    path: '/library/edit/:bestPracticeSlug/:stepId?',
    private: true,
    layout: AppLayoutNoFooter,
    component: CreateEditBestPractice
  },
  {
    path: '/ecoregions',
    layout: AppLayout,
    component: EcoregionsList
  },
  {
    path: '/ecoregion/:ecoregionSlug',
    layout: AppLayout,
    private: true,
    component: EcoregionProfile,
  },
  {
    path: '/ecoregion/:ecoregionSlug/specimen/:slug',
    layout: AppLayout,
    private: true,
    component: EcoregionSpecimenDetailsPage,
  },
  {
    path: '/ecoregion/:ecoregionSlug/specimen/:slug/review',
    layout: AppLayout,
    private: true,
    component: EcoregionSpecimenDetailsPage,
  },
  {
    path: '/ecoregion/:ecoregionSlug/specimen/:slug/nav',
    layout: AppLayoutNoFooter,
    private: true,
    component: SpecimenNav,
  },
  {
    path: '/ecoregion/:ecoregionSlug/species/:slug',
    layout: AppLayout,
    private: true,
    component: EcoregionSpeciesDetailsPage,
  },
  {
    path: '/ecoregion/:ecoregionSlug/species/:slug/dashboard',
    layout: AppLayout,
    component: EcoregionSpeciesDashboard
  },
  {
    path: '/ecoregion/:ecoregionSlug/species/:slug/edit/:stepId?',
    layout: AppLayoutNoFooter,
    component: EcoregionCreateEditSpecies,
  },
  {
    path: '/profile',
    layout: AppLayout,
    private: true,
    redirect: '/login',
    component: Profile
  },
  {
    path: '/profile/edit',
    layout: SettingLayout,
    private: true,
    redirect: '/login',
    component: EditProfile
  },
  {
    path: '/:username/bioregion',
    layout: AppLayout,
    private: true,
    redirect: '/login',
    component: BioRegion,
  },
  {
    path: '/profile/delete',
    layout: SettingLayout,
    private: true,
    redirect: '/login',
    component: DeleteProfile
  },
  {
    path: '/leaderboard',
    layout: AppLayout,
    component: GlobalLeaderboard
  },
  {
    path: '/species/new/:stepId?',
    layout: AppLayoutNoFooter,
    component: CreateEditSpecies,
  },
  {
    path: '/species/thankyou',
    layout: AppLayout,
    component: CreateSpeciesThankYou,
  },
  { // adding this generic species route so we are able to link when we don't have an ecoregion
    path: '/species/:slug',
    layout: AppLayout,
    component: SpeciesDetailsPage
  },
  { // adding this generic species edit route so we are able to direct people from generic species route
    path: '/species/:slug/edit/:stepId?',
    layout: AppLayout,
    component: CreateEditSpecies
  },
  {
    path: '/commons/:commonsSlug/species/:slug',
    layout: AppLayout,
    component: SpeciesDetailsPage
  },
  {
    path: '/commons/:commonsSlug/species/:slug/dashboard',
    layout: AppLayout,
    component: SpeciesDashboard
  },
  {
    path: '/commons/:commonsSlug/species/:slug/edit/:stepId?',
    layout: AppLayoutNoFooter,
    component: CreateEditSpecies,
  },
  {
    path: '/commons/:commonsSlug/specimen/:slug/dashboard',
    layout: AppLayout,
    component: SpecimenDashboard
  },
  {
    path: '/login',
    layout: '',
    component: Login
  },
  {
    path: '/logout',
    layout: '',
    component: 'div'
  },
  // generic specimen routes so we are able to link when we don't have an commons/ecoregion
  {
    path: '/specimen/:slug',
    layout: AppLayout,
    component: SpecimenDetailsPage,
  },
  {
    path: '/specimen/:slug/nav',
    layout: AppLayoutNoFooter,
    private: true,
    component: SpecimenNav,
  },
  {
    path: '/commons/specimen/new/:stepId?',
    layout: AppLayoutNoFooter,
    component: CreateEditSpecimen,
  },
  {
    path: '/commons/:commonsSlug/specimen/edit/:slug/:stepId?',
    layout: AppLayoutNoFooter,
    component: CreateEditSpecimen,
    private: true,
    redirect: '/login',
  },
  {
    path: '/commons/specimen/thankyou',
    layout: AppLayout,
    component: CreateEditSpecimenThankYou,
  },
  {
    path: '/commons/:commonsSlug/specimen/:slug',
    layout: AppLayoutNoFooter,
    component: SpecimenDetailsPage,
  },
  {
    path: '/commons/:commonsSlug/specimen/:slug/nav',
    layout: AppLayoutNoFooter,
    private: true,
    component: SpecimenNav,
  },
  {
    path: '/commons/:commonsSlug/specimen/:slug/quiz/:routeStopSlug',
    layout: AppLayoutNoFooter,
    private: true,
    component: SpecimenQuiz,
  },
  {
    path: '/commons/:commonsSlug/specimen/:slug/review',
    layout: AppLayout,
    component: SpecimenDetailsPage,
  },
  {
    path: '/s/:routeStopSlug',
    layout: AppLayoutNoFooter,
    component: RedirectFromShortUrl,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug',
    layout: AppLayoutNoFooter,
    component: RouteDetailsPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/start',
    layout: AppLayoutNoFooter,
    component: SelfGuidedRouteIntroPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/start/multiplayer',
    layout: AppLayoutNoFooter,
    component: MultiplayerIntroPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/multiplayer/new',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerManagePlayersPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/multiplayer/:gameId',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerOtherPlayerView,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/multiplayer/:multiplayerGameId/manage',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerManagePlayersPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/signInInvite',
    layout: AppLayoutNoFooter,
    component: RouteSignInInvitePage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/map',
    layout: AppLayoutNoFooter,
    component: RouteMapPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/feedback',
    layout: AppLayoutNoFooter,
    component: RouteFeedbackPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/:routeStopSlug',
    layout: AppLayoutNoFooter,
    component: RouteStopPage,
  },
  {
    path: '/commons/:commonsSlug/route/:routeSlug/:routeStopSlug/nav',
    layout: AppLayoutNoFooter,
    component: RouteStopNav,
  },
  {
    path: '/commons/:commonsSlug/quest/new',
    layout: AppLayoutNoFooter,
    private: true,
    component: CreateEditQuest,
  },
  {
    path: '/commons/:commonsSlug/quest/edit/:questSlug',
    layout: AppLayoutNoFooter,
    private: true,
    component: CreateEditQuest,
  },
  {
    path: '/commons/:commonsSlug/quest/:questSlug/dashboard',
    layout: AppLayoutNoFooter,
    private: true,
    component: QuestDashboard,
  },
  {
    path: '/commons',
    layout: AppLayout,
    private: true,
    component: CommonsList,
  },
  {
    path: '/commons/:commonsSlug',
    layout: AppLayout,
    private: true,
    component: CommonsProfile,
  },
  // generic routes for playing quests
  {
    path: '/route/:routeSlug/start',
    layout: AppLayoutNoFooter,
    component: SelfGuidedRouteIntroPage,
  },
  {
    path: '/route/:routeSlug/start/multiplayer',
    layout: AppLayoutNoFooter,
    component: MultiplayerIntroPage,
  },
  {
    path: '/route/:routeSlug/multiplayer/new',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerManagePlayersPage,
  },
  {
    path: '/route/:routeSlug/multiplayer/:gameId',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerOtherPlayerView,
  },
  {
    path: '/route/:routeSlug/multiplayer/:multiplayerGameId/manage',
    private: true,
    layout: AppLayoutNoFooter,
    component: MultiplayerManagePlayersPage,
  },
  {
    path: '/route/:routeSlug/signInInvite',
    layout: AppLayoutNoFooter,
    component: RouteSignInInvitePage,
  },
  {
    path: '/route/:routeSlug/map',
    layout: AppLayoutNoFooter,
    component: RouteMapPage,
  },
  {
    path: '/route/:routeSlug/feedback',
    layout: AppLayoutNoFooter,
    component: RouteFeedbackPage,
  },
  {
    path: '/route/:routeSlug/:routeStopSlug',
    layout: AppLayoutNoFooter,
    component: RouteStopPage,
  },
  {
    path: '/route/:routeSlug/:routeStopSlug/nav',
    layout: AppLayoutNoFooter,
    component: RouteStopNav,
  },
  {
    path: '/map',
    layout: AppLayoutNoFooter,
    component: MapView,
  },
  {
    path: '/mapping/new',
    layout: AppLayoutNoFooter,
    private: true,
    redirect: '/login',
    component: CreateEditMapping,
  },
  {
    path: '/mapping/edit/:slug',
    layout: AppLayoutNoFooter,
    private: true,
    redirect: '/login',
    component: CreateEditMapping,
  },
  {
    path: '/mapping',
    layout: AppLayoutNoFooter,
    private: true,
    redirect: '/login',
    component: MappingList,
  },
  {
    path: '/mapping/intro',
    layout: AppLayoutNoFooter,
    private: true,
    redirect: '/login',
    component: MappingToolIntro,
  },
  {
    path: '/play',
    layout: AppLayoutNoFooter,
    component: Play,
  },
  {
    path: '/chat/:username?',
    layout: AppLayoutNoFooter,
    private: true,
    redirect: '/login',
    component: ChatFullscreen,
  },
  {
    path: '/register',
    layout: '',
    component: Register
  },
  {
    path: '/password/edit',
    layout: SettingLayout,
    private: true,
    redirect: '/login',
    component: Change
  },
  {
    path: '/password/new',
    layout: '',
    component: Forgot
  },
  {
    path: '/password/reset',
    layout: '',
    component: Reset
  },
  {
    path: '/verify',
    layout: 'IframeLayout',
    component: Verify
  },
  {
    path: '/redeem',
    layout: 'IframeLayout',
    component: RedeemImpactPoints
  },
  {
    path: '/error',
    component: ViewError
  },
  {
    path: '*',
    component: ViewError
  }
];

export default routes;
