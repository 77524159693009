import React, { useContext, useEffect, useMemo, useState } from 'react';
import Onboarding from 'components/onboarding/Onboarding';
import { UserContext } from 'contexts/user/UserContextProvider';
import RoutesProgressSaved from 'pages/Commons/Quest/RoutesProgressSaved';
import { useLocation } from 'react-router';

/** This component is used in top level (App.js) and shows modals that should pop up in any route (onboarding, news, ...) */
const OverlayNotificationModal = (): React.ReactElement => {
  const { currentUser, isAuthenticated } = useContext(UserContext);
  const QuizProgressLocalStorage = useMemo(() => JSON.parse(localStorage.getItem('quizProgress')), []);

  // State variable to hold the sequence of modal names to be shown
  const [modalSequence, setModalSequence] = useState<string[]>([]);

  /* */
  /* Search Params */
  /* */
  const location = useLocation();
  const { search } = location;
  const searchParamsJoinClass = useMemo(() => { return new URLSearchParams(search).get('joinClass'); }, [search]);

  /* */
  /* Conditions */
  /* */
  // Check conditions and set the initial modal sequence when the component mounts
  useEffect(() => {
    const newModalSequence: string[] = [];

    if (currentUser?.username && !currentUser?.name && !searchParamsJoinClass) {
      newModalSequence.push('Onboarding');
    }

    if (QuizProgressLocalStorage?.length && isAuthenticated) {
      newModalSequence.push('RoutesProgressSaved');
    }

    setModalSequence(newModalSequence);
  }, [currentUser, isAuthenticated, QuizProgressLocalStorage, searchParamsJoinClass]);

  // Function to handle when a modal is closed
  // This would be needed for modals that don't get removed from the array when the useEffect above rund
  // const handleModalClose = () => {
  //   // Remove the first modal from the sequence after it's closed
  //   console.log('removing from modalSequence', modalSequence[0]);
  //   setModalSequence((prevSequence) => prevSequence.slice(1));
  // };

  // Get the modal to be shown
  const currentModalName = modalSequence.length > 0 ? modalSequence[0] : '';

  /* */
  /* Render */
  /* */
  const renderModal = () => {
     
    switch (currentModalName) {
      case 'Onboarding':
        return <Onboarding userInfo={{ currentUser }} />;
      case 'RoutesProgressSaved':
        return <RoutesProgressSaved />;
      default:
        return null;
       
    }
  };

  return <React.Fragment>{renderModal()}</React.Fragment>;
};

export default OverlayNotificationModal;
