import type { MouseEventHandler, PropsWithChildren } from 'react';
import { ChannelList, ChannelListProps, ChannelPreviewUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import MessagingChannelPreview from './MessagingChannelPreview';

type MessagingSidebarProps = {
  channelListOptions: {
    filters: ChannelListProps['filters'];
    sort: ChannelListProps['sort'];
    options: ChannelListProps['options'];
  };
  onClick: MouseEventHandler;
  onCreateChannel?: () => void;
  onPreviewSelect?: MouseEventHandler;
  channelId: string; // active channel id
};

const MessagingSidebar = ({
  channelListOptions,
  onClick,
  channelId,
  // onCreateChannel,
  // onPreviewSelect,
}: MessagingSidebarProps) => {

  return (
    <div
      className='str-chat messaging__sidebar str-chat__theme-light'
      id='mobile-channel-list'
      role="complementary"
      aria-label="Chat channels"
      onClick={onClick}
    >
      <ChannelList
        {...channelListOptions}
        customActiveChannel={channelId}
        Preview={(props: PropsWithChildren<ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>>) => <MessagingChannelPreview {...props} />}
      />
    </div>
  );
};

export default MessagingSidebar;
