import { gql, useMutation, useQuery } from '@apollo/client';
import SpecimensIcon from 'assets/icons/commonsIcon/specimens_icon.svg?react';
import MultilingualText from 'components/Multilingual/MultilingualText';
import NextPreviousButton from 'components/common/NextPreviousButton';
import LeavesIcon from 'components/svg/LeavesIcon';
import { CREATE_ROUTE_STOP_USER_STATUS } from 'pages/Commons/Quest/queries';
import { QuizProgressLocalStorage, QuizProgressLocalStorageRoute } from 'pages/Commons/Quest/useLocalStorageRouteData';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Route } from 'services/graphqltypes';

/** Checks if there are self-guided routes stops statuses saved in local storage; if yes, store to database, and show info modal about it. */
const RoutesProgressSaved = () => {
  const intl = useIntl();

  const localStorageQuizProgress = useMemo(() => JSON.parse(localStorage.getItem('quizProgress')) ?? [], []) as QuizProgressLocalStorage;
  const [showModal, setShowModal] = useState(true);
  const [updatedRoutes, setUpdatedRoutes] = useState<{ // each object in the array represents a single route whose stops were present in local storage and have been saved to the database
    routeId: string,
    specimenCount: number,
    impactPointsCount: number,
    stopsId: string[],
    routeName: JSON,
  }[]>([]);

  /* */
  /* Query */
  /* */
  // create query string based on the routes in localStorage
  // query for each route in localStorage, so that we know what mutations have to run
  const ROUTES_USER_STATUS = useMemo(() => {

    const ROUTES_USER_STATUS = localStorageQuizProgress.length > 0
      ? `
      query routesUserStatus {
        ${localStorageQuizProgress.map((route: QuizProgressLocalStorageRoute, index: number) => {
        return `route_${index}:route(id: "${route.routeId}") {
            id
            name
            routeStops {
              nodes {
                id
                currentUserHasCompleted
                impactPoints
              }
            }
          }` ;
      })}
      }
      `
      : '';

    return ROUTES_USER_STATUS;
  }, [localStorageQuizProgress]);

  const { loading, data: routesUserStatusData } = useQuery(gql`${ROUTES_USER_STATUS}`, { skip: localStorageQuizProgress.length === 0 });

  /* */
  /* Mutations */
  /* */
  const [createRouteStopUserStatus] = useMutation(CREATE_ROUTE_STOP_USER_STATUS);

  // for each route stop status in local storage, which is not yet saved in the db, run a mutation
  useEffect(() => {
    // create array of mutations promises
    const createRouteStopUserStatusMutations = routesUserStatusData && localStorageQuizProgress.flatMap(
      (localStorageRoute: QuizProgressLocalStorageRoute) => {
        const routeFromQuery = Object.values(routesUserStatusData).find((route: any) =>
          route.id === localStorageRoute.routeId) as Route;
        const createRouteStopUserStatusMutations = localStorageRoute.stopsStatus.map(
          (stopStatus) => {
            const stopFromQuery = routeFromQuery.routeStops.nodes.find((stop) => stop.id === stopStatus.stopId);
            // if user has completed this stop and saved in the database in the past, don't add into mutation array
            if (!stopFromQuery.currentUserHasCompleted && stopStatus.status) {

              setUpdatedRoutes((prevState) => {
                const updatedState = [...prevState];
                const routeToUpdateIndex = updatedState.findIndex((route) => route.routeId === localStorageRoute.routeId);
                if (routeToUpdateIndex !== -1) {
                  if (!updatedState[routeToUpdateIndex].stopsId.includes(stopStatus.stopId)) {
                    updatedState[routeToUpdateIndex].stopsId.push(stopStatus.stopId);
                    updatedState[routeToUpdateIndex].specimenCount += 1;
                    updatedState[routeToUpdateIndex].impactPointsCount += stopFromQuery.impactPoints;
                  }
                } else {
                  updatedState.push({
                    routeId: localStorageRoute.routeId,
                    routeName: routeFromQuery.name as JSON,
                    specimenCount: 1,
                    impactPointsCount: stopFromQuery.impactPoints,
                    stopsId: [stopStatus.stopId],
                  });
                }
                return updatedState;
              }
              );

              return createRouteStopUserStatus({
                variables: {
                  routeStopId: stopStatus.stopId,
                }
              });
            }

            return null;
          });

        return createRouteStopUserStatusMutations;
      });

    createRouteStopUserStatusMutations?.length > 0
      && Promise.all(createRouteStopUserStatusMutations).then((res) => {
        if (res) {
          localStorage.removeItem('quizProgress');
        }
      }).catch((error: any) => {
        if (error) {
          console.log('progress save error', error);
          // NotificationManager.error(
          //   `Error: ${intl.formatMessage({ id: 'error.try-again' })}`,
          //   null,
          //   3000,
          //   null,
          //   null,
          //   'filled'
          // );
        }
      });
  }, [routesUserStatusData, createRouteStopUserStatus, localStorageQuizProgress, intl]);

  /* */
  /* Render */
  /* */
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop='static' /* prevent closing modal when clicked outside it*/
      size='lg'
      className='rounded-modal'
    >
      <Modal.Header closeButton className='mr-3 mt-3 p-0' />
      <Modal.Body className='pt-0 mt-0 text-center'>
        {localStorageQuizProgress.length > 0
          ?
          loading
            ? <div className="loading-relative-position my-2" />
            : <div className='my-2'>
              <h1 className='text-center'>Your progress has been saved.</h1>
              {updatedRoutes.map((route, index) => {
                return (
                  <div className='text-center' key={index}>
                    <span className='font-weight-bold'>
                      <MultilingualText value={route.routeName} />:
                    </span>
                    <span className='ml-1'>
                      +{route.impactPointsCount} <LeavesIcon color='#60b6aa' size={25} className='ml-1 my-auto' /> +{route.specimenCount} <SpecimensIcon width={20} height={30} stroke="#60b6aa" className='ml-1 my-auto' />
                    </span>
                  </div>
                );
              })}
            </div>
          : null
        }

        <NextPreviousButton
          onClick={() => setShowModal(false)}
          buttonTextId='general.close'
        />

      </Modal.Body>
    </Modal>
  );
};

export default RoutesProgressSaved;
