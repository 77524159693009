import { gql } from '@apollo/client';
import { SPECIMEN_FRAGMENT } from 'pages/Bioregion/CreateEditSpecimenForm/SpecimenIdentification';

export const COMMUNITY_BASIC_INFO_FRAGMENT = gql`
fragment communityBasicInfoFragment on Community {
  slug
  name
  id
  avatarUrl
  aboutOneliner
  locationName
  locationAddress
  locationCoordinates { x, y }
}
`;
export const USER_BASIC_INFO_FRAGMENT = gql`
fragment userBasicInfoFragment on User {
  id
  avatarUrl
  name
  totalCredits
  username
  isTerritoryMentor
  isEcoregionMentor
}
`;

const ROUTE_STOPS_COUNT_FRAGMENT = gql`
fragment RouteStopsCountFragment on Route {
  completedRouteStops: routeStops(
    filter: {currentUserHasCompleted: {equalTo: true}}
  ) {
    totalCount
  }
}`;

export const ROUTE_CURRENT_USER_RATINGS = gql`
query RouteCurrentUserRatings(
  $routeSlug: String!
  $userId: UUID!
) {
  routeUserRatings(
    filter: {
      route: { slug: { equalTo: $routeSlug } }
      createdByUserId: { equalTo: $userId }
    }
  ) {
    totalCount
    nodes {
      id
      stars
    }
  }
}`;

const ROUTE_STOP_FRAGMENT = gql`
${ROUTE_STOPS_COUNT_FRAGMENT}
${SPECIMEN_FRAGMENT}
fragment RouteStopFragment on RouteStop {
  id
  name
  content
  slug
  routeId
  position
  currentUserTries
  currentUserHasCompleted
  impactPoints
  specimen {
    ...specimanFragment
  }
  route {
    id
    isCurrentUserInMultiplayerGame
    isCurrentUserLeadingAMultiplayerGame
    territoryId
    territory {
      id
      slug
    }
    routeManagers(filter: {communityExists: true}) {
      nodes {
        community {
          slug
          id
        }
      }
    }
    slug
    color
    ...RouteStopsCountFragment
  }
}
`;

export const ROUTE_MANAGER_COMMUNITY_FRAGMENT = gql`
  fragment RouteManagerCommunityFragment on Community {
    name
    id
    slug
    avatarUrl
    territory {
      id
      isCurrentUserMentor
    }
    parentCommunity {
      id
      slug
      territory {
        id
        isCurrentUserMentor
      }
    }
  }
`;
const ROUTE_MANAGER_USER_FRAGMENT = gql`
  fragment RouteManagerUserFragment on User {
    avatarUrl
    id
    name
    username
    totalCredits
  }
`;


export const ROUTE_FRAGMENT = gql`
${ROUTE_STOP_FRAGMENT}
${ROUTE_STOPS_COUNT_FRAGMENT}
${ROUTE_MANAGER_COMMUNITY_FRAGMENT}
${ROUTE_MANAGER_USER_FRAGMENT}
fragment RouteFragment on Route {
  users: routeManagers(filter: { userId: { isNull: false } }) {
    nodes {
      id
      user {
        ...RouteManagerUserFragment
      }
    }
  }
  communities: routeManagers(filter: { communityId: { isNull: false } }) {
    nodes {
      id
      community {
        ...RouteManagerCommunityFragment
        communityUsers(filter: { status: { equalTo: JOINED } }) {
          totalCount
        }
      }
    }
  }
  routeManagers {
    nodes {
      id
      community {
        ...RouteManagerCommunityFragment
      }
      user {
        ...RouteManagerUserFragment
      }
    }
  }
  accessibility
  bannerUrl
  name
  color
  isQrEnabled
  length
  id
  slug
  durationMin
  durationMax
  description
  biodiversity
  safety
  customTrackGeojson
  status
  createdByUserId
  ...RouteStopsCountFragment
  routeStops(orderBy: POSITION_ASC) {
    totalCount
    nodes {
      ...RouteStopFragment
    }
  }
  territory {
    id
    slug
    isCurrentUserMentor
    locationBoundingBox
  }
}`;

// used in list of routes
export const MINIMAL_ROUTE_FRAGMENT = gql`
${ROUTE_STOPS_COUNT_FRAGMENT}
fragment minimalRouteFragment on Route {
  bannerUrl
  name
  color
  length
  id
  slug
  durationMin
  durationMax
  status
  customTrackGeojson
  createdByUserId
  ...RouteStopsCountFragment
  routeStops(orderBy: POSITION_ASC) {
    totalCount
    nodes {
      id
      name
      position
      currentUserHasCompleted
      specimen {
        id
        coordinates { x, y }
      }
    }
  }
  routeManagers {
    nodes {
      id
      community {
        name
        id
        slug
        avatarUrl
        territory {
          id
          isCurrentUserMentor
        }
        parentCommunity{
          id
          slug
          territory {
            id
            isCurrentUserMentor
          }
        }
      }
      user {
        avatarUrl
        id
        name
        username
      }
    }
  }
  territory {
    id
    slug
  }
}`;

export const ROUTES_FRAGMENT_FOR_COMMONS = gql`
${MINIMAL_ROUTE_FRAGMENT}
fragment routesFragmentForCommon on Territory {
  publishedRoutes: routes(filter: {status: {equalTo: APPROVED_PUBLISHED}}) {
    totalCount
    nodes {
      ...minimalRouteFragment
    }
  }
  draftRoutes: routes(filter: {status: {equalTo: DRAFT}}) {
    nodes {
      ...minimalRouteFragment
    }
  }
  draftRoutesWithStops: routes(filter: {status: {equalTo: DRAFT}, routeStopsExist: true}) {
    nodes {
      ...minimalRouteFragment
    }
  }
}
`


export const ROUTE_STOP_BY_SLUG = gql`
${ROUTE_STOP_FRAGMENT}
query routeStopBySlug($slug: String!) {
  routeStopBySlug(slug: $slug) {
    ...RouteStopFragment
  }
}
`;

export const CREATE_ROUTE_STOP_USER_STATUS = gql`
${ROUTE_FRAGMENT}
mutation createRouteStopUserStatus($routeStopId: UUID!, $routeMultiplayerGameId: UUID) {
  createRouteStopUserStatus(
    input: {routeStopUserStatus: {routeStopId: $routeStopId, hasCompleted: true, routeMultiplayerGameId: $routeMultiplayerGameId}}
  ) {
    routeStopUserStatus {
      hasCompleted
      id
      updatedByUserId
    }
    routeStop {
      id
      impactPoints
      specimen {
        id
        currentUserHasObserved
      }
      route {
        ...RouteFragment
      }
    }
  }
}
`;

export const UPDATE_ROUTE_STOP_USER_STATUS = gql`
mutation updateRouteStopUserStatusByRouteStopIdAndCreatedByUserId($createdByUserId: UUID!, $routeStopId: UUID!, $tries: Int!) {
  updateRouteStopUserStatusByRouteStopIdAndCreatedByUserId(
    input: {patch: {tries: $tries}, routeStopId: $routeStopId, createdByUserId: $createdByUserId}
  ) {
    routeStopUserStatus {
      id
      tries
    }
  }
}
`;

export const ROUTE_BY_SLUG = gql`
${ROUTE_FRAGMENT}
query routeBySlug ($slug: String!) {
  routeBySlug(slug: $slug) {
    ...RouteFragment
  }
}
`;

export const USER_INTRO_VIEWS = gql`
query userIntroViews($userId: UUID!, $introType: IntroType) {
  userIntroViews(filter: {userId: {equalTo: $userId}, intro: {equalTo: $introType}}) {
    nodes {
      id
      hasViewed
    }
  }
}
`;

export const CREATE_USER_INTRO_VIEW = gql`
mutation createUserIntroView(
  $userId: UUID!
  $intro: IntroType!
) {
  createUserIntroView(
    input: {
      userIntroView: { userId: $userId, intro: $intro, hasViewed: true }
    }
  ) {
    query {
      userIntroViews(
        filter: {
          userId: { equalTo: $userId }
          intro: { equalTo: $intro }
        }
      ) {
        nodes {
          id
          hasViewed
        }
      }
    }
  }
}
`;

export const UPDATE_ROUTE = gql`
${ROUTE_FRAGMENT}
${ROUTES_FRAGMENT_FOR_COMMONS}
mutation updateRoute(
  $accessibility: Int
  $bannerUrl: String
  $biodiversity: Int
  $color: String
  $description: JSON
  $durationMax: Int
  $durationMin: Int
  $id: UUID!
  $length: Int
  $name: JSON
  $safety: Int
  $status: RouteStatus
  $isQrEnabled: Boolean
) {
  updateRoute(
    input: {
      patch: {
        accessibility: $accessibility
        bannerUrl: $bannerUrl
        biodiversity: $biodiversity
        color: $color
        isQrEnabled: $isQrEnabled
        description: $description
        durationMax: $durationMax
        durationMin: $durationMin
        length: $length
        name: $name
        safety: $safety
        status: $status
      }
      id: $id
    }
  ) {
    route {
      ...RouteFragment
      territory{
        ...routesFragmentForCommon
      }
    }
  }
}
`;

export const CREATE_ROUTE = gql`
mutation createRoute(
  $accessibility: Int
  $bannerUrl: String
  $biodiversity: Int
  $color: String
  $description: JSON
  $durationMax: Int
  $durationMin: Int
  $length: Int
  $name: JSON!
  $safety: Int
  $status: RouteStatus!
  $isQrEnabled: Boolean!
  $territoryId: UUID
) {
  createRoute(
    input: {
      route: {
        accessibility: $accessibility
        bannerUrl: $bannerUrl
        biodiversity: $biodiversity
        color: $color
        isQrEnabled: $isQrEnabled
        description: $description
        durationMax: $durationMax
        durationMin: $durationMin
        length: $length
        name: $name
        safety: $safety
        territoryId: $territoryId
        status: $status
      }
    }
  ) {
    route {
      id
      slug
      accessibility
      bannerUrl
      biodiversity
      color
      isQrEnabled
      description
      durationMax
      durationMin
      length
      name
      safety
      territoryId
      status
    }
  }
}
`;

export const CREATE_ROUTE_STOP = gql`
mutation createRouteStop(
  $position: Int
  $routeId: UUID!
  $specimenId: UUID!
  $content: JSON
) {
  createRouteStop(
    input: {
      routeStop: {
        position: $position
        routeId: $routeId
        specimenId: $specimenId
        content: $content
      }
    }
  ) {
    routeStop {
      id
    }
  }
}
`;

export const UPDATE_ROUTE_STOP = gql`
mutation updateRouteStop(
  $position: Int
  $routeId: UUID!
  $specimenId: UUID!
  $content: JSON
  $id: UUID!
) {
  updateRouteStop(
    input: {
      patch: {
        position: $position
        routeId: $routeId
        specimenId: $specimenId
        content: $content
      }
      id: $id
    }
  ) {
    routeStop {
      id
    }
  }
}
`;

export const DELETE_ROUTE_STOP = gql`
mutation deleteRouteStop($id: UUID!) {
  deleteRouteStop(input: { id: $id }) {
    route {
      id
    }
  }
}
`;

export const TERRITORY_BY_SLUG = gql`
${ROUTES_FRAGMENT_FOR_COMMONS}
query territoryBySlug($slug: String!) {
  territoryBySlug(slug: $slug) {
    id
    ...routesFragmentForCommon
  }
}
`;
