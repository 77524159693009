import { Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { getCodeFromError, getFieldError } from 'helpers/errors';
import { getUiTranslation } from 'helpers/utils-typescript';
import { Label, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { REGISTER } from 'services/auth';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UserContext } from 'contexts/user/UserContextProvider';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import IntlMessages from 'helpers/IntlMessages';
import React, { useMemo, useContext } from 'react';

 
const RegisterForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const currentLang = useSelector((state) => state.settings.locale);
  const { refetchCurrentUser } = useContext(UserContext);

  const redirectUrl = useMemo(() => {
    const redeemCode = new URLSearchParams(location.search).get('redeemCode');
    if (redeemCode) {
      return `/verify?redeemCode=${redeemCode}`;
    }
    return '/verify';
  }, [location]);

  /* */
  /* Queries and mutations */
  /* */
  const [register] = useMutation(REGISTER);

  async function handleSubmit(values, { setFieldError, setSubmitting }) {
    try {
      const username = values.email.split('@')[0].trim();
      await register({ variables: { username, email: values.email, password: values.password } }).then(() => {
        setSubmitting(false);
        if (history.location.state?.['from']) {
          history.push({
            pathname: '/verify',
            state: {
              from: history.location.state.from,
            }
          });
        } else {
          history.push(redirectUrl);
        }
        refetchCurrentUser();
      });
    } catch (e) {
      console.error(e);
      const code = getCodeFromError(e); // extracts the error code from the error object
      // const exception = getExceptionFromError(e);
      // const fields = exception && exception['fields']; // errors that I tested did not have the 'fields' field -> this is probably useless
      const [fieldname, messageId] = getFieldError(code);
      const errMessage = messageId ? intl.formatMessage({ id: messageId }) : e.toString(); // if we don't get a messageId, at least show the text of the error as it comes from the backend
      if (errMessage) setFieldError(fieldname, errMessage); // display the error in the form
    }
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(getUiTranslation('general.invalid-email', currentLang)).required(getUiTranslation('general.required', currentLang)),
    password: yup.string().required(getUiTranslation('general.required', currentLang)),
  });

  return (
    <div>
      <Formik
        initialValues={{
          username: '',
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup className="form-group has-float-label">
              <Label>
                <IntlMessages id="user.email" />
              </Label>
              <Field
                type="email"
                name="email"
                id="email"
                placeholder="Enter your e-mail"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={errors.email ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.email && errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
            </FormGroup>

            <FormGroup className="form-group has-float-label">
              <Label>
                <IntlMessages id="user.password" />
              </Label>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Choose a secure password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={errors.password ? 'form-control is-invalid' : 'form-control'}
              />
              {touched.password && errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
            </FormGroup>
            <div className="submit-container">
              <div className="mb-3">
                <Button block variant="primary" className="text-one" type="submit" disabled={isSubmitting}>
                  <span className="label">
                    <IntlMessages id="user.register-button" />
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center text-link">
        <p>
          <IntlMessages id="user.has-account" />
          <span> </span>
          <Link
            to={{
              pathname: '/login',
              state: { from: history.location.state?.['from'] },
            }}
          >
            <IntlMessages id="user.login-title" />
          </Link>
        </p>
      </div>
    </div>
  );
};
export default RegisterForm;
